import React, { useRef } from 'react';
import {Icon} from '../../../decor/SarasIcons'
import SarasHotKeys from '../../../notifications/SarasHotKeys'
import SarasTrans from '../../../notifications/SarasTrans'

const SarasButton2 = (props)=>{
    const { id, disabled, onClick, viewSwitch, showText, containerStyle, containerClass, className, style, text, actionv, ksc, children, icon, tooltip, buttonkey} = props
    const btn = useRef();

    const onButtonClick = (e) => {
        if(onClick) onClick(e, viewSwitch);
    }

    const onHotkeyPress = (e) => {
        onButtonClick(btn, viewSwitch);
    }
    
    const renderBtn = () => {
        let BtnIcon = Icon[icon];
        if(BtnIcon){
            if(showText === 1){
                return(
                    <span id={id} className={containerClass || "uiButtonIconWithText"} style={containerStyle}
                        onClick={onButtonClick}>
                        <BtnIcon ref={btn}
                        className={className || 'uiDefaultIconButton'} 
                        buttonkey={buttonkey}
                        style = {style}
                        />
                        <span className="uiButtonText"><SarasTrans value={text || children} /></span>
                    </span>
                    )
                }
            else{
                return(
                    <span id={id} className={containerClass} style={containerStyle}>
                        <BtnIcon ref={btn}
                        className={className || 'uiDefaultIconButton'} onClick={onButtonClick}
                        buttonkey={buttonkey}
                        style = {style}
                        />
                    </span>
                    )
                }
        }
        else{
            return(
                <button ref={btn} id={id} disabled={disabled} 
                buttonkey={buttonkey}
                className={className} onClick={onButtonClick} 
                style={style} actionv={actionv}>
                    <span><SarasTrans value={text || children} /></span>
                </button>
            );
        }
    }

    return(
        <SarasHotKeys ksc={ksc} onHotKey={onHotkeyPress} tooltip={tooltip}>
            {renderBtn() }
        </SarasHotKeys>
    )
}

export default SarasButton2